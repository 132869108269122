import { styled } from "../../core/core";

    styled.css `

        display: flex;
        flex-direction:row;
        gap: 10px;

        padding-left: 10px;

        padding-right: 10px;

    `.installGlobal("nav-bar");

export default class NavBar extends HTMLElement {
    
}

customElements.define("nav-bar", NavBar);
