import { styled } from "../../core/core";
import { IEditableAttribute } from "../../core/EditableAttributes";
import HtmlControl from "../../core/HtmlControl";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";

    styled.css `
        position: relative;

        &::part(hover) {
            transform: rotateY(180deg);
        }

        & > * {
            grid-row: 1;
            grid-column: 1;
            backface-visibility: hidden;
        }

        & > *:first-child {
            z-index:2;
        }

        & > *:last-child {
            z-index:2;
            transform: rotateY(180deg);
        }

        &::part(root) {
            display: inline-block;
        }

        &::part(toggle) {
            position: absolute;
            top: 10px;
            right: 10px;
            font: var(--fa-font-solid);
            font-size: 1rem;
            background-color: white;
            color: #444;
            border: solid 1px darkgray;
            border-radius: 9999px;
            width: 20px;
            height: 20px;
            display: inline-grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            cursor: pointer;
            &::after {
                content: "\uf2f1";
            }
            &:hover {
                color: blue;
            }
        }

        &::part(container) {
            display: inline-grid;
            perspective: 1000px;

            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            transition: 0.6s;
            transform-style: preserve-3d;
        }

        &[first-visible=yes],&[first-visible=true],&[first-visible=on] {
            & > *:first-child {
                backface-visibility: visible;
            }
        }

    `.installGlobal("flip-box");

    styled.css `
        & flip-box {

            &:hover {
                &::part(container) {
                    transform: rotateY(180deg);
                }
            }

            &::part(toggle) {
                display: none;
            }
        }
    `.installGlobal("body:not([editing])");

export default class FlipBox extends TemplateControl {

    static observedAttributes = ["first-visible"];

    get editableAttributes(): IEditableAttribute[] {
        return [
            { name: "first-visible", type: "boolean", suggest: ["yes", "no", "true", "false"] }
        ];
    }
    
    root: ShadowRoot;

    container: HTMLElement;

    i = 0;

    async prepare() {
        // do nothing
        const root = this.root = this.attachShadow({ mode:"open", delegatesFocus: true });
        XNode.append(root, <div part="root">
            <div part="container"></div>
            <div part="toggle" title="Toggle" event-click={ () => this.container.part.toggle("hover") }>
            </div>
        </div>);

        this.container = root.querySelector(`[part="container"]`);

        this.addEventListener("click", () => {
            if (this.isContentEditable) {
                return;
            }
            this.container.part.toggle("hover");
        });
    }
    onChildAdded(child: HTMLElement) {
        // do nothing
        const name = `child-${++this.i}`;
        XNode.append(this.container, <slot part={name} name={name} />);

        child.setAttribute("slot", name);
    }
    onChildRemoved(child: HTMLElement) {
        // do nothing
        child.assignedSlot?.remove();
    }


}

customElements.define("flip-box", FlipBox);
