import { ComponentHelper } from "../../core/ComponentHelper";
import { asCssDataUrl, styled } from "../../core/core";
import HtmlControl from "../../core/HtmlControl";
import ScreenApi from "../../ScreenApi";

// const svg = asCssDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path strokeColor="currentColor" d="M320 240L160 384 0 240l0-48 320 0 0 48z"/></svg>`);

// const svgPlus = asCssDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path strokeColor="currentColor" d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>`);

// const svgMinus = asCssDataUrl(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path  strokeColor="currentColor" d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>`);

    styled.css `
        display: block;
        position: relative;
        top: 0;
        padding-right: 20px;
        gap: 0;

        & > input {
            display: none;
        }

        & > nav {
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            gap: 10px;
            padding: 10px;
            min-width: 100px;
            & > *:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        @media (min-width: 701px) {

            &::after {
                font: var(--fa-font-solid);
                width: 2ch;
                height: 3ch;
                font-size: 0.9rem;
                padding-left:0.2rem;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1ch 1ch;
                content: "\uf0d7";
            }

            & > nav {
                position: absolute;
                margin-top: 10px;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
            }

            &:not(:hover) > nav {
                display: none;
            }
        }


        @media (max-width: 701px) {

            & > input {
                position: absolute;
                right: 0;
                top: 12px;
                width: 21px;
                height: 22px;
                display: inline-block;
                background-color: transparent;
                background-position: 2px 0;
                border-radius: 2px;
                border-width: 1px;
                border-style: solid;
                border-color: transparent;
                background-repeat: no-repeat;
                appearance: none;
                outline: none;
            }

            & > div.image-div {
                position: absolute;
                right: 0;
                top: 14px;
                width: 21px;
                height: 22px;
                display: inline-block;
                pointer-events: none;
                font: var(--fa-font-solid);
                &::after {
                    content: "\x2b";
                }
            }

            & {
                border: solid 1px currentColor;
                padding-right: 30px;
                border-radius: 5px;
            }

            & > input:not(:checked) {
                background-position: 2px 0;
                & ~ nav {
                    display: none;
                }
            }
            & > input:checked {
                background-position: 2px 0;
                & ~ div.image-div {
                    &::after {
                        content: "\uf068";
                    }
                }
            }
        }

    `.installGlobal("menu-item");

export default class MenuItem extends HtmlControl {


    async prepare() {

        if (this.isContentEditable) {
            return;
        }

        await ComponentHelper.waitForReady();


        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        let div = document.createElement("div");
        div.className = "image-div";
        this.insertAdjacentElement("afterbegin", checkbox);
        this.insertBefore(div, checkbox.nextSibling);

        if (!ScreenApi.isMobile) {
            return;
        }

        this.addEventListener("click", (e) => {
            let target = e.target as HTMLElement;
            
            const anchor = ComponentHelper.findAncestorOrSelf(target, (x) => x.previousElementSibling === div);

            if (anchor) {
                checkbox.checked = !checkbox.checked;
            }
            

        });
    }
}

    styled.css `
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;

    & > * {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @media (min-width: 501px) {

        & > :not(:first-child) {
            position: absolute;
            right: 0;
            top: 2ch;
            background-color: inherit;
            border-radius: 10px;
        }


        &:not(:hover) > :not(:first-child) {
            display: none;
        }
    }

    `.installGlobal("menu-items");

export class MenuItems extends HTMLElement {

}

customElements.define("menu-item", MenuItem);
customElements.define("menu-items", MenuItems);
