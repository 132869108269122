import { ComponentHelper } from "../../core/ComponentHelper";
import { styled } from "../../core/core";

    styled.css `
    @keyframes marquee {
       0%   { transform: translate(0, 0); }
        100% { transform: translate(-100%, 0); }
    }
    `.installGlobal("");

    styled.css `

        white-space: nowrap;
        overflow: hidden;

        &::part(marquee) {
            display: inline-block;
            padding-left: 100%;
            animation: marquee 15s linear infinite;
        }

    `.installGlobal("marquee-text");

class MarqueeText extends HTMLElement {

    connectedCallback() {
        this.init().catch(console.error);
    }

    async init() {
        await ComponentHelper.waitForReady();

        const text = this.textContent;
        this.textContent = "";
        const root = this.attachShadow({ mode: "open"});
        const m = document.createElement("div");
        m.textContent = text;
        m.setAttribute("part", "marquee");
        root.appendChild(m);
    }

}

customElements.define("marquee-text", MarqueeText);