import { ComponentHelper } from "../../core/ComponentHelper";
import Defer from "../../core/Defer";
import { IEditableAttribute } from "../../core/EditableAttributes";
import ScrollHelper from "../../core/ScrollHelper";
import { StringHelper } from "../../core/StringHelper";
import TemplateControl from "../../core/TemplateControl";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";

    styled.css `

        display: grid !important;
        box-sizing: border-box;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto 1fr auto;
        position: relative;

        &:not([has-children]) {
            display: none !important;
        }

        & > * {
        }


        & * {
            box-sizing: border-box;
        }

        &::part(left) {
            grid-row: 1 / span 3;
            grid-column: 1;
            font-size: 2rem;
            margin-left: 10px;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            z-index: 2;
            color: white;
            text-shadow: 0 0 3px black;
            &::after {
                content: "\uf053"
            }
        }

        &::part(right) {
            grid-row: 1 / span 3;
            grid-column: 3;
            font-size: 2rem;
            margin-right: 10px;
            place-self: center;
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            font: var(--fa-font-solid);
            font-size: 2rem;
            z-index: 2;
            color: white;
            text-shadow: 0 0 3px black;
            &::after {
                content: "\uf054"
            }
        }

        &:not([contenteditable=true])[marker=off], &:not([contenteditable=true])[marker=false] {
            &::part(left) {
                display: none;
            }
            &::part(right) {
                display: none;
            }

        }

        &::part(canvas) {
            grid-row: 1 / span 3 ;
            grid-column: 1 / span 3;
            display: grid;
            position: relative;
            grid: repeat(1, 1fr) / auto-flow 100%;
            column-gap: 10px;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: hidden;
            overflow-x: auto;
            scrollbar-width: none;
        }

        &::part(child) {
            grid-row: 1;
            display: block;
            cursor: grab;
            min-width: 100%;
        }

        &:not([scrolling]) {

            &::part(canvas) {
                scroll-snap-type: x mandatory;
            }

            &::part(child) {
                scroll-snap-align: center;
                scroll-snap-stop: always;
            }
        }

        &::part(indicator) {
            grid-row: 3 ;
            grid-column: 2;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            gap: 5px;
            min-height: 30px;
            z-index: 2;
        }

        &::part(circle) {
            cursor: pointer;
            font-size: 0.6rem;
            text-shadow: 0 0 9px #0000008f;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-color: currentColor;
            -webkit-text-stroke-width: 1px;
            &::before {
                content: "⚫";
            }
        }
        &::part(active)::before {
            content: "⚪";    
        }

    `.installGlobal("carousel-slider");

let partId = 1;

const added = Symbol("added");

class CarouselSlider extends TemplateControl {

    static observedAttributes = ["animate", "duration"];

    current: HTMLElement;

    indicator: HTMLElement;
    canvas: HTMLElement;

    intersectionObserver: IntersectionObserver;
    root: ShadowRoot;

    disableObserver = false;

    timer: any;

    prepared = false;

    get editableAttributes(): IEditableAttribute[] {
        return [
            { name: "animate", type: "boolean", suggest: ["yes", "no", "true", "false"] },
            { name: "duration", type: "time", suggest: ["3s", "5s", "7s"] }
        ];
    }

    async onFirstPrepare() {
        this.setupAnimation();
        this.prepared = true;
    }

    setupAnimation() {
        if (this.isContentEditable) {
            return;
        }
        const { timer } = this;
        if (timer) {
            clearInterval(timer)
        }
        const duration = StringHelper.parseTime(this.getAttribute("duration"), 5000);
        if (!/yes|true/i.test(this.getAttribute("animate"))) {
            return;
        }
        this.timer = setInterval(() => this.rotate(), duration);
    }

    attributeChangedCallback(name, oldValue, newValue) {
        switch(name) {
            case "animate":
            case "duration":
                this.setupAnimation();
                break;
        }
    }

    connectedCallback() {
        this.intersectionObserver = new IntersectionObserver((entries) => this.inspectIntersection(entries), { threshold: 0.8, root: this });
        super.connectedCallback();
    }

    inspectIntersection(entries: IntersectionObserverEntry[]) {
        if (this.disableObserver) {
            return;
        }
        try {
            let max = null as IntersectionObserverEntry;
            for (const element of entries) {
                if (element.isIntersecting) {
                    if (max === null) {
                        max = element;
                    } else {
                        if (max.intersectionRatio < element.intersectionRatio) {
                            max = element;
                        }
                    }
                }
            }
            if (max) {
                this.updateIndicator(max);
            }
        } catch (error) {
            console.error(error);
        }
    }

    updateIndicator(max: IntersectionObserverEntry) {
        const itemIndex = max.target.getAttribute("item-index");
        const { children } = this.indicator;
        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            const ei = element.getAttribute("item-indicator-index");
            if (itemIndex === ei) {
                this.current = (max.target as HTMLSlotElement).assignedElements()[0] as HTMLElement;
                element.setAttribute("part", "circle active");    
            } else {
                element.setAttribute("part", "circle");    
            }
        }
    }

    async onAfterConnected() {
        const { children } = this.canvas;
        for (let index = 0; index < children.length; index++) {
            const element = children[index];
            this.intersectionObserver.observe(element);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.intersectionObserver.disconnect();
    }


    indicatorClick = (e: MouseEvent) => {
        // console.log(e);
        let { target, value } = ComponentHelper.getParentAttribute(e.target as HTMLElement, (x) => x.getAttribute("item-indicator-index"));
        if (!value) {
            return;
        }
        const slot = this.canvas.querySelector(`[item-index="${value}"]`) as HTMLSlotElement;
        this.current = slot.assignedElements()[0] as HTMLElement;
        ScrollHelper.bringIntoView(this.current, true);
    };

    async prepare() {
        const root = this.attachShadow({ mode: "open"})
        this.root = root;
        XNode.render(root, <div>
            <i
                event-click={() => this.moveLeft() }
                part="left"></i>
            <div part="canvas"></div>
            <div part="indicator" event-click={this.indicatorClick}></div>
            <i
                event-click={() => this.moveRight() }
                part="right"></i>
        </div>);

        const indicator = root.querySelector(`[part="indicator"]`) as HTMLElement;
        this.indicator = indicator;
        const canvas = root.querySelector(`[part="canvas"]`) as HTMLElement;
        this.canvas = canvas;


        let disposableMouseMove: Disposable;
        let disposableMouseEnd: Disposable;
        let disposableMouseLeave: Disposable;

        const dispose = (me: MouseEvent) => {

            me.preventDefault();
            me.stopImmediatePropagation();

            disposableMouseMove?.[Symbol.dispose]();
            disposableMouseEnd?.[Symbol.dispose]();
            disposableMouseLeave?.[Symbol.dispose]();

            this.removeAttribute("scrolling");

            disposableMouseEnd = null;
            disposableMouseMove = null;
            disposableMouseLeave = null;
            this.disableObserver = false;

            const records = this.intersectionObserver.takeRecords();
            this.inspectIntersection(records);
        };

        if (this.isContentEditable) {
            return;
        }

        this.canvas.addEventListener("dragstart", (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
        });

        this.canvas.addEventListener("mousedown", (me: MouseEvent) => {

            me.preventDefault();
            me.stopImmediatePropagation();
            this.disableObserver = true;

            this.setAttribute("scrolling", "yes");

            disposableMouseMove ??= this.bindEvent( this.canvas, "mousemove", (me: MouseEvent) => {

                me.preventDefault();
                me.stopImmediatePropagation();

                this.canvas.scrollBy({ left: -me.movementX, behavior: "instant"});

            });
            disposableMouseEnd ??= this.bindEvent( this.canvas, "mouseup", dispose);
            disposableMouseEnd ??= this.bindEvent( this.canvas, "mouseleave", dispose);
        });
    }

    onChildAdded(iterator: HTMLElement) {

        if (iterator[added]) {
            return;
        }
        iterator[added] = true;

        this.setAttribute("has-children", "true");

        const child = this.ownerDocument.createElement("slot");
        const { canvas, indicator, current } = this;
        if (!current) {
            this.current = iterator;
        }

        const i = partId++;
        const name = `child${i}`;
        child.setAttribute("name", name);
        child.setAttribute("part", "child");
        child.setAttribute("item-index", i as any as string);
        canvas.appendChild(child);
        iterator.slot = name;
        const span = this.ownerDocument.createElement("span");
        span.setAttribute("part", "circle");
        span.setAttribute("item-indicator-index", i as any as string);
        indicator.appendChild(span);
        span.addEventListener("click", () => {
            // this.current = iterator;
            // this.setupAnimation();
            // ScrollHelper.bringIntoView(this.current, true);
            // this.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start"});
            this.setCurrent(iterator, true);
        });
        this.intersectionObserver.observe(child);

        if (this.prepared) {
            this.queueUpdate();
        }
    }

    @Defer(100)
    queueUpdate() {
        const entries = this.intersectionObserver.takeRecords();
        this.inspectIntersection(entries);
    }

    onChildRemoved(child: Node) {
        if (!this.children.length) {
            this.removeAttribute("has-children");
        }
    }

    moveLeft() {
        const previous = this.current.previousElementSibling as HTMLElement
            ?? this.current.parentElement.lastElementChild as HTMLElement;
        this.setCurrent(previous, true);
    }

    setCurrent(current: HTMLElement, setupAnimation = false) {
        this.current = current;
        if (setupAnimation) {
            this.setupAnimation();
        }

        const target = current.assignedSlot;

        const parent = target.parentElement;
        if(!target.nextElementSibling?.nextElementSibling) {
            // bring first...
            const first = parent.firstElementChild as HTMLElement;
            first.remove();
            parent.appendChild(first);
        }
        if (!target.previousElementSibling?.previousElementSibling) {
            const last = parent.lastElementChild as HTMLElement;
            last.remove();
            parent.insertAdjacentElement("afterbegin", last);
        }
        ScrollHelper.bringIntoView(this.current, true);
    }

    moveRight() {
        const next = this.current.nextElementSibling as HTMLElement
            ?? this.current.parentElement.firstElementChild as HTMLElement;
        if (!next) {
            return;
        }
        this.setCurrent(next, true);
    }

    
    rotate() {
        let next = this.current.nextElementSibling as HTMLElement;
        if (!next) {
            next = this.current.parentElement.firstElementChild as HTMLElement;
            if (!next) {
                return;
            }
        }
        this.current = next;
        ScrollHelper.bringIntoView(this.current, true);
    }

}

customElements.define("carousel-slider", CarouselSlider);
